import React, { useState } from "react";
import { Link } from "react-router-dom";

export default function Landing() {

    const [active, setActive] = useState(0)

    return (
        <div>
            <div className="flex flex-row w-screen justify-between items-center p-4" >

                <img src={require('../assets/images/logo.png')} alt='logo' className=" w-14" />

                <div className="flex flex-row justify-between items-center w-[30%]">
                    <Link to="/">Home</Link>
                    <Link to="/#about">About</Link>
                    <Link to="/#services">Services</Link>
                    <Link to="/Privacy">Privacy Policy</Link>
                </div>

                <div>
                    <div className="border-2 border-slate-900 w-40 flex justify-center items-center p-2 rounded-3xl hover:bg-black" onClick={() => {
                        window.open('mailto:sossfinance24@gmail.com')
                    }}><p className="hover:text-white">Contact us</p></div>
                </div>
            </div>

            <div className="h-[60vh] bg-[url('/src/assets/images/bg.png')]">

                <div className="flex flex-row justify-between items-end h-[100%]">
                    <div className="w-[50%] flex flex-col justify-between items-start h-[60%] mb-40 ml-16">

                        <p className="text-white font-extrabold text-5xl w-[90%]">We design, build and modernize <span className="text-yellow-400">software applications</span></p>

                        <p className="text-white w-[71%] font-extralight">Engage Net Solutions to build a remote, Agile software development team  to co-create or update your digital products or platforms with the  latest web, mobile and cloud technologies.</p>

                        <div className="border-2 border-white w-40 flex justify-center items-center p-2 rounded-3xl hover:bg-black"><p className="text-white">Contact us</p></div>
                    </div>

                    <div>
                        <img src={require('../assets/images/person.png')} alt='person' className="w-[650px]" />
                    </div>
                </div>

            </div>

            <div className="flex flex-row justify-center w-screen items-center">
                <div className="flex flex-col justify-between items-start w-[400px] bg-yellow-400 h-[380px] pt-10 pb-10 pl-10 pr-5  rounded-3xl mt-[-75px]">
                    <p className="font-semibold text-2xl w-[70%]">Create Software and Apps To Meet Your Customer Needs</p>
                    <p className="text-lg w-[80%] font-extralight">Work with a software company that develops products & platforms that  elevate your customers’ experience, earn their loyalty & grow your  business.</p>
                </div>

                <div className="flex flex-col w-[40%] ml-16 p-12" id="about">
                    <p className="font-bold text-2xl mb-8">About us</p>
                    <p className="mb-4 font-extralight">We are a company who specialize in delivering top-notch software development consulting services, empowering businesses and individuals to bring their innovative ideas to life. Our team of expert developers, designers, and project managers collaborate to build custom mobile and web applications that meet the unique needs of our clients</p>

                    <p className="font-extralight">We strive to deliver high-quality software solutions that drive business growth, improve efficiency, and exceed our clients' expectations. Let us help you turn your vision into a reality!</p>

                </div>
            </div>

            <div className="flex flex-col justify-center items-center mt-8 mb-8">
                <p className="text-center font-extrabold w-[37%] text-3xl">We Don’t Just Deliver Digital Products. We Give Your Customers Digital Experiences</p>
            </div>

            <div className="flex flex-row justify-between items-center mx-20" id="services">

                <div>

                    <p className="font-extrabold text-5xl w-[420px] mb-16">What We Offer As A solution</p>

                    <p className="font-extralight w-[600px] mb-4">
                        - Mobile App Development: We design and develop intuitive, user-friendly mobile applications for iOS and Android platforms, ensuring seamless performance and exceptional user experience.
                    </p>

                    <p className="font-extralight w-[600px] mb-4">
                        - Web App Development: Our team crafts robust, scalable web applications using cutting-edge technologies, tailored to meet the specific requirements of our clients
                    </p>

                    <p className="font-extralight w-[600px] mb-4">
                        - Full-Service Solutions: We offer comprehensive services.
                    </p>

                    <p className="font-extralight w-[600px] mb-4">
                        - Development: Building software from scratch or enhancing existing applications.
                    </p>

                    <p className="font-extralight w-[600px] mb-4">
                        - Deployment: Ensuring smooth deployment and integration with existing infrastructure.
                    </p>

                    <p className="font-extralight w-[600px] mb-4">
                        - Maintenance: Providing ongoing support, updates, and optimization to ensure long-term success.
                    </p>

                </div>

                <img src={require('../assets/images/phones.png')} alt='person' className="w-[650px]" />


            </div>

            <div className="w-screen bg-black pt-4 pb-8 flex flex-col justify-between items-center">

                <p className="text-white font-bold text-3xl pb-8">Customer Review</p>

                <div className="flex flex-row justify-around items-center">
                    <div className="bg-slate-100 w-[17%] h-[300px] p-4 flex flex-col justify-between items-center rounded-2xl">
                        <p className="font-extralight text-center">
                            They are legit, and their rate is nice. Their deposit is also  fast, and again, I like their rates. Maybe we should even form a  community of “Sossians”.  and the customer support is really effective. Thanks for the amazing app.
                        </p>
                        <p className="font-bold">Caroline</p>
                        <div>
                            <img src={require('../assets/images/stars.png')} alt='person' className="w-[150px]" />
                        </div>
                    </div>

                    <div className="bg-slate-100 w-[17%] h-[300px] p-4 flex flex-col justify-between items-center rounded-2xl">
                        <p className="font-extralight text-center">
                            They are legit, and their rate is nice. Their deposit is also  fast, and again, I like their rates. Maybe we should even form a  community of “Sossians”.  and the customer support is really effective. Thanks for the amazing app.
                        </p>
                        <p className="font-bold">Caroline</p>
                        <div>
                            <img src={require('../assets/images/stars.png')} alt='person' className="w-[150px]" />
                        </div>
                    </div>

                    <div className="bg-slate-100 w-[17%] h-[300px] p-4 flex flex-col justify-between items-center rounded-2xl">
                        <p className="font-extralight text-center">
                            They are legit, and their rate is nice. Their deposit is also  fast, and again, I like their rates. Maybe we should even form a  community of “Sossians”.  and the customer support is really effective. Thanks for the amazing app.
                        </p>
                        <p className="font-bold">Caroline</p>
                        <div>
                            <img src={require('../assets/images/stars.png')} alt='person' className="w-[150px]" />
                        </div>
                    </div>
                </div>
            </div>

            <div className="flex flex-col justify-center items-center mt-10">
                <p className="text-center text-3xl w-[35%] font-extralight">Got a Software or App Development Project in Mind?</p>
                <div className="bg-yellow-400 w-[120px] flex flex-row justify-center items-center rounded-3xl py-1 mt-4" onClick={() => {
                    window.open('mailto:sossfinance24@gmail.com')
                }}>
                    <p>Let's talk</p>
                </div>
            </div>

            <div className="h-[80vh] mx-40 flex flex-row justify-between items-center">

                <div className="flex flex-col justify-between h-[50%]">
                    <p className="text-3xl">
                        Frequently Asked Question
                    </p>

                    <div className="border-2 border-slate-100 rounded-xl w-[63%] p-4">

                        <p className="font-bold">Still have questions?</p>
                        <p className="font-extralight w-[90%]">
                            We’d be glad to answer any questions you have. Click here to <span className="text-yellow-400 underline">Contact Support</span>
                        </p>
                    </div>
                </div>

                <div className="flex flex-col justify-around items-center">

                    <div className={active === 0 ? "w-[450px] border-2 border-yellow-300 rounded-2xl p-4" : "w-[450px] border-2 border-slate-200 rounded-3xl p-4 mt-2"} onClick={() => {
                        setActive(0)
                    }}>
                        <div className="flex flex-row justify-between items-center mb-2">
                            <p className="font-bold">What services does SOSS offer</p>
                            {active === 0 ? <p className="bg-yellow-400 text-white w-[15px] rounded-full h-[15px] text-2xl flex flex-col justify-center items-center pb-1">-</p> : <p className="bg-yellow-400 text-white w-[15px] rounded-full h-[15px] text-2xl flex flex-col justify-center items-center pb-1">+</p>}
                        </div>
                        {active === 0 && <p className="font-extralight w-[87%]">We offer software consultancy services to customers who are looking to get top notch technological solutions either for new or existing ventures or software</p>}
                    </div>

                    <div className={active === 1 ? "w-[450px] border-2 border-yellow-300 rounded-2xl p-4 mt-2" : "w-[450px] border-2 border-slate-200 rounded-3xl p-4 mt-2"} onClick={() => {
                        setActive(1)
                    }}>
                        <div className="flex flex-row justify-between items-center mb-2">
                            <p className="font-bold">How long does it take to develop a software</p>
                            {active === 1 ? <p className="bg-yellow-400 text-white w-[15px] rounded-full h-[15px] text-2xl flex flex-col justify-center items-center pb-1">-</p> : <p className="bg-yellow-400 text-white w-[15px] rounded-full h-[15px] text-2xl flex flex-col justify-center items-center pb-1">+</p>}
                        </div>
                        {active === 1 && <p className="font-extralight w-[87%]">The duration of development is highly dependent on the complexity and schedule of your project, on an average it take 4 weeks for a complete design and development process</p>}
                    </div>


                    <div className={active === 2 ? "w-[450px] border-2 border-yellow-300 rounded-2xl p-4 mt-2" : "w-[450px] border-2 border-slate-200 rounded-3xl p-4 mt-2"} onClick={() => {
                        setActive(2)
                    }}>
                        <div className="flex flex-row justify-between items-center mb-2">
                            <p className="font-bold">How much does it cost to develop softwares</p>
                            {active === 2 ? <p className="bg-yellow-400 text-white w-[15px] rounded-full h-[15px] text-2xl flex flex-col justify-center items-center pb-1">-</p> : <p className="bg-yellow-400 text-white w-[15px] rounded-full h-[15px] text-2xl flex flex-col justify-center items-center pb-1">+</p>}
                        </div>
                        {active === 2 && <p className="font-extralight w-[87%]">The prices for our offerings differ depening on the complexity of your project and what softwares you require, mobile apps, web apps etc.</p>}
                    </div>

                    <div className={active === 3 ? "w-[450px] border-2 border-yellow-300 rounded-2xl p-4 mt-2" : "w-[450px] border-2 border-slate-200 rounded-3xl p-4 mt-2"} onClick={() => {
                        setActive(3)
                    }}>
                        <div className="flex flex-row justify-between items-center mb-2">
                            <p className="font-bold">Can SOSS Optimize an already built solution</p>
                            {active === 3 ? <p className="bg-yellow-400 text-white w-[15px] rounded-full h-[15px] text-2xl flex flex-col justify-center items-center pb-1">-</p> : <p className="bg-yellow-400 text-white w-[15px] rounded-full h-[15px] text-2xl flex flex-col justify-center items-center pb-1">+</p>}
                        </div>
                        {active === 3 && <p className="font-extralight w-[87%]">Yes, we are able to maintain and optimize your already built software or solutions, either adding new features, reviewing code base and updatiing technology being used.</p>}
                    </div>

                    <div className={active === 4 ? "w-[450px] border-2 border-yellow-300 rounded-2xl p-4 mt-2" : "w-[450px] border-2 border-slate-200 rounded-3xl p-4 mt-2"} onClick={() => {
                        setActive(4)
                    }}>
                        <div className="flex flex-row justify-between items-center mb-2">
                            <p className="font-bold">Does SOSS offer Post Contract services</p>
                            {active === 4 ? <p className="bg-yellow-400 text-white w-[15px] rounded-full h-[15px] text-2xl flex flex-col justify-center items-center pb-1">-</p> : <p className="bg-yellow-400 text-white w-[15px] rounded-full h-[15px] text-2xl flex flex-col justify-center items-center pb-1">+</p>}
                        </div>
                        {active === 4 && <p className="font-extralight w-[87%]">We are able to reopen completed contracts either in the view of maintenance or other cases as you might require, a reopened contract is considered a new contract or an adendumn</p>}
                    </div>

                </div>

            </div>


            <div className="h-[40vh] bg-black flex flex-col justify-between items-center pb-4">

                <p className=" font-extrabold text-5xl text-white text-center w-[45%] mt-8">Stay updated by subscribing to our newsletter.</p>
                <p className="font-extralight text-white text-center w-[35%]">Stay informed and up-to-date with the latest news and updates from our company by subscribing to our newsletter.</p>

                <div className="w-[25%] relative">
                    <input type="email" className="w-[100%] h-12 p-4 rounded-lg" placeholder="Email Address" />
                    <div className="absolute top-1 right-1 bg-black px-4 py-4 h-10 rounded-3xl  flex flex-row justify-between items-center" onClick={() => window.location.reload()}>
                        <p className="text-white">Subscribe</p>
                    </div>
                </div>

                <p className="font-extralight text-white">© 2024 SOSS Technologies. All Rights Reserved.</p>

            </div>

        </div>
    )
}