import { BrowserRouter, Routes, Route } from "react-router-dom";
import Landing from "./Pages/landing.tsx";
import Privacy from "./Pages/Privacy.tsx";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Landing />}></Route>
        <Route path="/privacy" element={<Privacy />}></Route>
      </Routes>
    </BrowserRouter>

  );
}

export default App;
